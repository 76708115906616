import ApiService from "@/core/services/api.service";

export const GET_ANSWERS = "getAnswers";
export const CREATE_ANSWER = "createAnswer";
export const GET_ANSWER = "getAnswer";
export const UPDATE_ANSWER = "updateAnswer";
export const DELETE_ANSWER = "deleteAnswer";
export const SET_ERROR = "setError";

const state = {
  errors: null,
};

const actions = {
  [GET_ANSWERS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("answers", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_ANSWER](context, pk) {
    return new Promise(resolve => {
      ApiService.get("answers/" + pk)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [DELETE_ANSWER](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("answers/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_ANSWER](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("answers/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },


  [UPDATE_ANSWER](context, payloads) {
    return new Promise(resolve => {
      ApiService.put("answers/"+payloads.id+'/', payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

};

const getters = {

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
