import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
   mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/tweets",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/tweets",
          name: "tweets",
          component: () => import("@/view/pages/Dashboard.vue")
        },
      ]
    },
     {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/tusers",
          name: "tusers",
          component: () => import("@/view/pages/Tusers.vue")
        },
      ]
    },
      {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/reports",
          name: "reports",
          component: () => import("@/view/pages/Reports.vue")
        },
      ]
    },

      {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/Users.vue")
        },
      ]
    },


    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
  ]
});
