import ApiService from "@/core/services/api.service";

export const GET_TWEETS = "getTweets";
export const CREATE_TWEET = "createTweet";
export const GET_REPORTS = "getReports";
export const GET_TWEET = "getTweet";
export const UPDATE_TWEET = "updateTweet";
export const DELETE_TWEET = "deleteTweet";
export const SET_ERROR = "setError";

const state = {
  errors: null,
};

const actions = {
  [GET_TWEETS](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("tweets/ajax/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_TWEET](context, pk) {
    return new Promise(resolve => {
      ApiService.get("tweets/" + pk)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [DELETE_TWEET](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("tweets/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_TWEET](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("tweets/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [GET_REPORTS](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("tweets/get_reports/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [UPDATE_TWEET](context, payloads) {
    return new Promise(resolve => {
      ApiService.put("tweets/"+payloads.id+'/', payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

};

const getters = {

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
