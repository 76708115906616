import ApiService from "@/core/services/api.service";

export const GET_TUSERS = "getTusers";
export const CREATE_TUSER = "createTuser";
export const GET_TUSER = "getTuser";
export const UPDATE_TUSER = "updateTuser";
export const DELETE_TUSER = "deleteTuser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
};

const actions = {
  [GET_TUSERS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("tusers")
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_TUSER](context, pk) {
    return new Promise(resolve => {
      ApiService.get("tusers/" + pk)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [DELETE_TUSER](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("tusers/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_TUSER](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("tusers/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [UPDATE_TUSER](context, payloads) {
    return new Promise(resolve => {
      ApiService.put("tusers/"+payloads.pk+'/', payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

};

const getters = {

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
